import { gql } from '@apollo/client';
import { UserFragment } from './fragments';

export const CURRENT_USER = gql`
  query CURRENT_USER {
    currentUser {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const GET_CATEGORIES = gql`
  query GET_CATEGORIES {
    categories {
      _id
      active
      slug
      name
    }
  }
`;

export const GET_CATEGORY = gql`
  query GET_CATEGORY($filter: FilterFindOneCategoryInput!) {
    category(filter: $filter) {
      active
      slug
      name
      description
    }
  }
`;

export const GET_REGIONS = gql`
  query GET_REGIONS {
    regions {
      _id
      name
      active
    }
  }
`;

export const GET_REGION = gql`
  query GET_REGIONS($filter: FilterFindOneRegionInput!) {
    region(filter: $filter) {
      _id
      name
      description
      active
    }
  }
`;

export const GET_BENEFITS = gql`
  query GET_BENEFITS {
    benefits {
      _id
      active
      name
      slug
      region {
        _id
        name
      }
    }
  }
`;

export const GET_BENEFIT = gql`
  query GET_BENEFIT($filter: FilterFindOneBenefitInput!) {
    regions {
      name
      _id
    }
    categories {
      name
      _id
    }
    benefit(filter: $filter) {
      active
      slug
      name
      description
      shortDescription
      photos
      points
      price
      quantity
      categories {
        _id
      }
      region {
        _id
      }
    }
  }
`;

export const GET_BENEFIT_RELATIONS = gql`
  query GET_BENEFITS_RELATIONS {
    regions {
      name
      _id
    }
    categories {
      name
      _id
    }
  }
`;

export const GET_QUIZZES = gql`
  query GET_QUIZZES {
    quizzes {
      _id
      name
      active
    }
  }
`;

export const GET_QUIZ = gql`
  query GET_QUIZ($filter: FilterFindOneQuizInput!) {
    quiz(filter: $filter) {
      _id
      active
      name
      description
      photo
      video
      file
      questions {
        _id
        name
        type
        points
        options {
          _id
          name
          correct
        }
      }
    }
  }
`;

export const GET_ZONES = gql`
  query GET_ZONES {
    zones {
      _id
      active
      name
    }
  }
`;

export const GET_ZONE = gql`
  query GET_ZONE($filter: FilterFindOneZoneInput!) {
    zone(filter: $filter) {
      slug
      active
      name
      description
      representatives {
        slug
        firstName
        lastName
        email
      }
    }
  }
`;

export const GET_USERS = gql`
  query GET_USERS {
    getUsers {
      _id
      slug
      firstName
      lastName
      active
    }
  }
`;

export const GET_USERS_RELATIONS = gql`
  query GET_USERS_RELATIONS {
    zones {
      name
      _id
    }
    routes {
      name
      _id
    }
    regions {
      name
      _id
    }
  }
`;

export const GET_USER = gql`
  query GET_USER($filter: FilterFindOneUserInput!) {
    zones {
      name
      _id
    }
    routes {
      name
      _id
    }
    regions {
      name
      _id
    }
    user(filter: $filter) {
      slug
      firstName
      lastName
      email
      birthDate
      privilege
      active
      photo
      phone {
        mobile
        local
      }
      zones {
        _id
      }
      routes {
        _id
      }
      region {
        _id
      }
    }
  }
`;

export const GET_CLIENTS = gql`
  query GET_CLIENTS {
    clients {
      active
      clientCode
      user {
        firstName
        lastName
      }
    }
  }
`;

export const GET_CLIENTS_RELATIONS = gql`
  query GET_CLIENTS_RELATIONS {
    zones {
      name
      _id
    }
  }
`;

export const GET_CLIENT = gql`
  query GET_CLIENT($filter: FilterFindOneClientInput!) {
    client(filter: $filter) {
      _id
      clientCode
      dni
      dniType
      active
      points
      avgExpense
      totalExpense
      quantityOfBenefits
      user {
        _id
        slug
        firstName
        lastName
        email
        birthDate
        photo
        phone {
          mobile
          local
        }
      }
      business {
        _id
        name
        rif
        clientCode
        address {
          state
          municipality
          neighborhood
          avenue
          local
          reference
        }
        phone {
          mobile
          local
        }
        zone {
          _id
        }
        region {
          _id
        }
        area {
          _id
        }
        route {
          _id
        }
        saleChannel {
          _id
        }
        storeType {
          _id
        }
        priceList {
          _id
        }
      }
    }
    regions {
      _id
      name
    }
    areas {
      _id
      name
    }
    zones {
      _id
      name
    }
    routes {
      _id
      name
    }
    saleChannels {
      _id
      name
    }
    storeTypes {
      _id
      name
    }
    priceLists {
      _id
      name
    }
  }
`;

export const GET_AREAS = gql`
  query GET_AREAS {
    areas {
      _id
      name
      active
    }
  }
`;

export const GET_AREA = gql`
  query GET_AREA($filter: FilterFindOneAreaInput!) {
    area(filter: $filter) {
      _id
      name
      description
      active
    }
  }
`;

export const GET_FAQS = gql`
  query GET_FAQS {
    FAQs {
      _id
      name
      active
    }
  }
`;

export const GET_FAQ = gql`
  query GET_FAQ($filter: FilterFindOneFAQInput!) {
    FAQ(filter: $filter) {
      _id
      name
      description
      active
    }
  }
`;

export const GET_ROUTES = gql`
  query GET_ROUTES {
    routes {
      _id
      name
      active
    }
  }
`;

export const GET_ROUTE = gql`
  query GET_ROUTE($filter: FilterFindOneRouteInput!) {
    route(filter: $filter) {
      _id
      name
      description
      active
    }
  }
`;

export const GET_SALE_CHANNELS = gql`
  query GET_SALE_CHANNELS {
    saleChannels {
      _id
      name
      active
    }
  }
`;

export const GET_SALE_CHANNEL = gql`
  query GET_SALE_CHANNELS($filter: FilterFindOneSaleChannelInput!) {
    saleChannel(filter: $filter) {
      _id
      name
      description
      active
    }
  }
`;

export const GET_STORE_TYPES = gql`
  query GET_STORE_TYPES {
    storeTypes {
      _id
      name
      active
    }
  }
`;

export const GET_STORE_TYPE = gql`
  query GET_STORE_TYPE($filter: FilterFindOneStoreTypeInput!) {
    storeType(filter: $filter) {
      _id
      name
      description
      active
    }
  }
`;

export const GET_BRANDS = gql`
  query GET_BRANDS {
    brands {
      _id
      name
      active
    }
  }
`;

export const GET_BRANDS_RELATIONS = gql`
  query GET_BRANDS_RELATIONS {
    brandGroups {
      _id
      name
    }
  }
`;

export const GET_BRAND = gql`
  query GET_BRAND($filter: FilterFindOneBrandInput!) {
    brandGroups {
      _id
      name
    }
    brand(filter: $filter) {
      _id
      name
      description
      marketed
      photo
      illicit
      competence
      codeMerkant
      active
      group {
        _id
      }
    }
  }
`;

export const GET_PRICE_LIST_RELATIONS = gql`
  query GET_PRICE_LIST_RELATIONS {
    brands {
      _id
      name
    }
  }
`;

export const GET_PRICE_LISTS = gql`
  query GET_PRICE_LISTS {
    priceLists {
      _id
      name
      active
    }
  }
`;

export const GET_PRICE_LIST = gql`
  query GET_PRICE_LIST($filter: FilterFindOnePriceListInput!) {
    brands {
      _id
      name
    }
    priceList(filter: $filter) {
      _id
      name
      active
      prices {
        _id
        amount
        brand {
          _id
          name
        }
      }
    }
  }
`;

export const GET_BRAND_GROUPS = gql`
  query GET_BRAND_GROUPS {
    brandGroups {
      _id
      name
      active
    }
  }
`;

export const GET_BRAND_GROUP = gql`
  query GET_BRAND_GROUP($filter: FilterFindOneBrandGroupInput!) {
    brandGroup(filter: $filter) {
      _id
      name
      description
      active
    }
  }
`;

export const GET_TRANSACTIONS = gql`
  query GET_TRANSACTIONS($filter: FilterFindManyTransactionInput!) {
    transactions(filter: $filter) {
      _id
      active
      client {
        clientCode
      }
    }
  }
`;

export const GET_TRANSACTION = gql`
  query GET_TRANSACTION($filter: FilterFindOneTransactionInput!) {
    transaction(filter: $filter) {
      _id
      amount
      description
      client {
        clientCode
      }
    }
  }
`;

export const GET_CLIENT_FILTERS = gql`
  query GET_CLIENT_FILTERS {
    regions {
      _id
      name
    }
    zones {
      _id
      name
    }
    routes {
      _id
      name
    }
    saleChannels {
      _id
      name
    }
    storeTypes {
      _id
      name
    }
    priceLists {
      _id
      name
    }
  }
`;

export const SEARCH_CLIENT = gql`
  query SEARCH_CLIENT($filter: SearchClientInput!) {
    searchClient(filter: $filter) {
      active
      clientCode
      business {
        _id
        name
      }
      user {
        _id
        firstName
        lastName
      }
    }
  }
`;

export const GET_NOTIFICATION_COUNT = gql`
  query GET_NOTIFICATION_COUNT($filter: FilterCountNotificationInput!) {
    notificationCount(filter: $filter)
  }
`;

export const GET_NOTIFICATIONS = gql`
  query GET_NOTIFICATIONS($filter: FilterFindManyNotificationInput!) {
    notifications(filter: $filter, sort: _ID_DESC) {
      _id
      name
      message
      type
      active
      createdAt
    }
  }
`;

export const ADMIN_DASHBOARD = gql`
  query ADMIN_DASHBOARD($date: Date) {
    adminDashboard(date: $date) {
      accountReceivables {
        _id {
          month
          year
        }
        count
        value
      }
      volumes {
        _id {
          month
          year
        }
        count
        value
        aspirationalPremium
        premium
        valueForMoney
      }
      users {
        _id {
          year
          month
        }
        count
      }
      transactions {
        _id
        count
        percentage
      }
      clients {
        total
        register
        tm
      }
      points {
        earn
        expend
      }
      historicPoints {
        earn
        expend
      }
      benefitRelation
    }
  }
`;

export const GET_GRAPHS_INFO = gql`
  query GET_GRAPHS_INFO($id: MongoID!, $clientCode: String!) {
    volumeSticks(
      filter: { clientCode: $clientCode }
      limit: 20
      sort: _ID_ASC
    ) {
      _id
      aspirationalPremium
      premium
      valueForMoney
      date
    }
    clientGraphsInfo(id: $id) {
      accounts {
        _id
        year
        month
        value
      }
      availabilities {
        _id
        year
        month
        points {
          percentage
        }
      }
      volumes {
        _id
        aspirationalPremium
        premium
        valueForMoney
        year
        month
      }
      transactions {
        _id
        type
        amount
        createdAt
      }
    }
  }
`;

export const CLIENT_REPORT = gql`
  query CLIENT_REPORT {
    clientReport {
      success
      data
      err
    }
  }
`;

export const TRANSACTION_REPORT = gql`
  query TRANSACTION_REPORT($startDate: Date!, $endDate: Date!) {
    transactionReport(startDate: $startDate, endDate: $endDate) {
      success
      data
      err
    }
  }
`;

export const TRANSACTION_REPORT_TYPE = gql`
  query TRANSACTION_REPORT_TYPE(
    $startDate: Date!
    $endDate: Date!
    $types: [String!]!
  ) {
    transactionReportByType(
      startDate: $startDate
      endDate: $endDate
      types: $types
    ) {
      success
      data
      err
    }
  }
`;

export const SEARCH_CLIENT_BY_COURSE = gql`
  query SEARCH_CLIENT_BY_COURSE($data: SearchClientByCoursesInput!) {
    searchClientByCourses(data: $data) {
      _id
      clientCode
      user {
        _id
        firstName
        lastName
      }
      business {
        _id
        name
        region {
          _id
          name
        }
        route {
          _id
          name
        }
        zone {
          _id
          name
        }
      }
      quizzes {
        _id
        quiz {
          _id
          name
        }
        answers {
          _id
          options {
            _id
            name
          }
          correct {
            _id
            name
          }
        }
        points
      }
    }
  }
`;
