const DEV_ENDPOINT = `http://localhost:3000`;
const PROD_ENDPOINT = `https://api.plataformaunidos.net`;
export const NOGQL_ENDPOINT =
  process.env.NODE_ENV === 'development' ? DEV_ENDPOINT : PROD_ENDPOINT;

export const DEV_CHAT_ENDPOINT = `http://localhost:3001`;
export const DEV_CHAT_WS_ENDPOINT = `ws://localhost:3001`;

export const PROD_CHAT_ENDPOINT = `https://chat.plataformaunidos.net`;
export const PROD_CHAT_WS_ENDPOINT = `wss://chat.plataformaunidos.net`;

export const ENDPOINT =
  process.env.NODE_ENV === 'development'
    ? `${DEV_ENDPOINT}/graphql`
    : `${PROD_ENDPOINT}/graphql`;

export const CHAT_ENDPOINT =
  process.env.NODE_ENV === 'development'
    ? DEV_CHAT_ENDPOINT
    : PROD_CHAT_ENDPOINT;

export const CHAT_WS_ENDPOINT =
  process.env.NODE_ENV === 'development'
    ? DEV_CHAT_WS_ENDPOINT
    : PROD_CHAT_WS_ENDPOINT;
