import { gql } from '@apollo/client';

export const UserFragment = gql`
  fragment UserFragment on User {
    _id
    slug
    firstName
    lastName
    email
    privilege
    active
    photo
    token
  }
`;
