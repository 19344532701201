/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import NProgress from 'nprogress';
import Head from 'next/head';
import es from 'date-fns/locale/es';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { useRouter } from 'next/router';
import { useApollo } from '../lib/useApollo';
import AppProvider from '../context/AppProvider';
import AuthProvider from '../context/AuthContext';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles.css';

registerLocale('es', es);
setDefaultLocale('es');

// @ts-ignore
function MyApp({ Component, pageProps, err }: AppProps) {
  const [token, setToken] = React.useState<string | undefined>(undefined);
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      setToken(
        typeof sessionStorage.getItem('token') === 'undefined' ||
          sessionStorage.getItem('token') === null ||
          sessionStorage.getItem('token') === 'undefined'
          ? '{}'
          : JSON.parse(sessionStorage.getItem('token'))
      );
    }
  }, []);
  const apolloClient = useApollo({ ...pageProps.initialApolloState, token });
  const router = useRouter();
  React.useEffect(() => {
    router.events.on('routeChangeStart', () => {
      NProgress.start();
    });

    router.events.on('routeChangeComplete', () => {
      NProgress.done();
    });

    router.events.on('routeChangeError', () => {
      NProgress.done();
    });
  }, [router]);
  return (
    <ApolloProvider client={apolloClient}>
      <Head>
        <title>Plataforma Unidos</title>
      </Head>
      <AppProvider>
        <AuthProvider>
          <Component {...pageProps} err={err} />
        </AuthProvider>
      </AppProvider>
    </ApolloProvider>
  );
}

export default MyApp;
