import React from 'react';
import { useQuery, useMutation, ApolloQueryResult } from '@apollo/client';
import { useRouter } from 'next/router';
import { SIGN_OUT } from '../graphql/mutations';
import { CURRENT_USER } from '../graphql/queries';
import { User } from '../models';

type TAuthContext = {
  user?: User;
  setUser?: React.Dispatch<React.SetStateAction<User>>;
  refetch?: (variables?: Partial<Record<string, any>>) => Promise<
    ApolloQueryResult<{
      currentUser: User;
    }>
  >;
};

export const AuthContext = React.createContext<TAuthContext>({});

interface AuthProviderProps {
  children?: React.ReactNode;
}

export default function AuthProvider({ children }: AuthProviderProps) {
  const router = useRouter();
  const [signOut] = useMutation(SIGN_OUT);
  const { data, loading, error, refetch } = useQuery<{ currentUser: User }>(
    CURRENT_USER
  );
  const [user, setUser] = React.useState<User>(null);
  React.useEffect(
    function syncUserWithState() {
      if (!loading && data) {
        if (data && data.currentUser && data.currentUser.privilege >= 1) {
          if (typeof window !== 'undefined') {
            sessionStorage.setItem(
              'token',
              JSON.stringify(data?.currentUser?.token)
            );
          }
          setUser(data?.currentUser);
        } else {
          signOut();
          if (typeof window !== 'undefined') {
            sessionStorage.removeItem('token');
          }
          if (router.asPath !== '/sign-in') {
            router.push('/sign-in');
          }
        }
      }
    },
    [data, loading]
  );
  const context = React.useMemo(
    () => ({ user, setUser, refetch }),
    [user, setUser, refetch]
  );
  return (
    <AuthContext.Provider value={context}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
}
