import { gql } from '@apollo/client';
import { UserFragment } from './fragments';

export const SIGN_IN = gql`
  mutation SIGN_IN($data: SignInInput!) {
    signIn(data: $data) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const SIGN_OUT = gql`
  mutation SIGN_OUT {
    signOut {
      success
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation CREATE_CATEGORY($record: CreateOneCategoryInput!) {
    createCategory(record: $record) {
      record {
        slug
      }
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UPDATE_CATEGORY(
    $record: UpdateOneCategoryInput!
    $filter: FilterUpdateOneCategoryInput
  ) {
    updateCategory(filter: $filter, record: $record) {
      record {
        slug
      }
    }
  }
`;

export const CREATE_REGION = gql`
  mutation CREATE_REGION($record: CreateOneRegionInput!) {
    createRegion(record: $record) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_REGION = gql`
  mutation UPDATE_REGION(
    $filter: FilterUpdateOneRegionInput!
    $record: UpdateOneRegionInput!
  ) {
    updateRegion(filter: $filter, record: $record) {
      record {
        _id
      }
    }
  }
`;

export const CREATE_BENEFIT = gql`
  mutation CREATE_BENEFIT($record: CreateOneBenefitInput!) {
    createBenefit(record: $record) {
      record {
        slug
      }
    }
  }
`;

export const UPDATE_BENEFIT = gql`
  mutation UPDATE_BENEFIT(
    $record: UpdateOneBenefitInput!
    $filter: FilterUpdateOneBenefitInput!
  ) {
    updateBenefit(record: $record, filter: $filter) {
      record {
        slug
      }
    }
  }
`;

export const CREATE_QUIZ = gql`
  mutation CREATE_QUIZ($data: CreateQuizInput!) {
    createQuiz(data: $data) {
      _id
    }
  }
`;

export const UPDATE_QUIZ = gql`
  mutation UPDATE_QUIZ($data: UpdateQuizInput!) {
    updateQuiz(data: $data) {
      _id
    }
  }
`;

export const CREATE_ZONE = gql`
  mutation CREATE_ZONE($record: CreateOneZoneInput!) {
    createZone(record: $record) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_ZONE = gql`
  mutation UPDATE_ZONE(
    $record: UpdateOneZoneInput!
    $filter: FilterUpdateOneZoneInput!
  ) {
    updateZone(record: $record, filter: $filter) {
      record {
        _id
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation CREATE_USER($record: RegisterUserInput!) {
    registerUser(data: $record) {
      slug
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UPDATE_USER($record: UpdateUserInput!) {
    updateUserCustom(data: $record) {
      slug
    }
  }
`;

export const CREATE_CLIENT = gql`
  mutation CREATE_CLIENT($data: CreateClientInput!) {
    createClient(data: $data) {
      clientCode
    }
  }
`;

export const UPDATE_CLIENT = gql`
  mutation UPDATE_CLIENT(
    $filter: FilterUpdateOneClientInput!
    $record: UpdateOneClientInput!
  ) {
    updateClient(record: $record, filter: $filter) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_BUSINESS = gql`
  mutation UPDATE_BUSINESS(
    $filter: FilterUpdateOneBusinessInput!
    $record: UpdateOneBusinessInput!
  ) {
    updateBusiness(filter: $filter, record: $record) {
      record {
        _id
      }
    }
  }
`;

export const CREATE_AREA = gql`
  mutation CREATE_AREA($record: CreateOneAreaInput!) {
    createArea(record: $record) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_AREA = gql`
  mutation UPDATE_AREA(
    $filter: FilterUpdateOneAreaInput!
    $record: UpdateOneAreaInput!
  ) {
    updateArea(filter: $filter, record: $record) {
      record {
        _id
      }
    }
  }
`;

export const CREATE_FAQ = gql`
  mutation CREATE_FAQ($record: CreateOneFAQInput!) {
    createFAQ(record: $record) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_FAQ = gql`
  mutation UPDATE_FAQ(
    $filter: FilterUpdateOneFAQInput!
    $record: UpdateOneFAQInput!
  ) {
    updateFAQ(filter: $filter, record: $record) {
      record {
        _id
      }
    }
  }
`;

export const CREATE_ROUTE = gql`
  mutation CREATE_ROUTE($record: CreateOneRouteInput!) {
    createRoute(record: $record) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_ROUTE = gql`
  mutation UPDATE_ROUTE(
    $filter: FilterUpdateOneRouteInput!
    $record: UpdateOneRouteInput!
  ) {
    updateRoute(filter: $filter, record: $record) {
      record {
        _id
      }
    }
  }
`;

export const CREATE_SALE_CHANNEL = gql`
  mutation CREATE_SALE_CHANNEL($record: CreateOneSaleChannelInput!) {
    createSaleChannel(record: $record) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_SALE_CHANNEL = gql`
  mutation UPDATE_SALE_CHANNEL(
    $filter: FilterUpdateOneSaleChannelInput!
    $record: UpdateOneSaleChannelInput!
  ) {
    updateSaleChannel(filter: $filter, record: $record) {
      record {
        _id
      }
    }
  }
`;

export const CREATE_STORE_TYPE = gql`
  mutation CREATE_STORE_TYPE($record: CreateOneStoreTypeInput!) {
    createStoreType(record: $record) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_STORE_TYPE = gql`
  mutation UPDATE_STORE_TYPE(
    $filter: FilterUpdateOneStoreTypeInput!
    $record: UpdateOneStoreTypeInput!
  ) {
    updateStoreType(filter: $filter, record: $record) {
      record {
        _id
      }
    }
  }
`;

export const CREATE_BRAND = gql`
  mutation CREATE_BRAND($record: CreateOneBrandInput!) {
    createBrand(record: $record) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_BRAND = gql`
  mutation UPDATE_BRAND(
    $filter: FilterUpdateOneBrandInput!
    $record: UpdateOneBrandInput!
  ) {
    updateBrand(filter: $filter, record: $record) {
      record {
        _id
      }
    }
  }
`;

export const SYNC_BRANDS_WITH_MERKANT = gql`
  mutation SYNC_BRANDS_WITH_MERKANT {
    syncBrandWithMerkant {
      success
      err
    }
  }
`;

export const CREATE_PRICE_LIST = gql`
  mutation CREATE_PRICE_LIST($data: CreatePriceListInput!) {
    createPriceList(data: $data) {
      _id
    }
  }
`;

export const UPDATE_PRICE_LIST = gql`
  mutation UPDATE_PRICE_LIST($data: UpdatePriceListInput!) {
    updatePriceList(data: $data) {
      _id
    }
  }
`;

export const CREATE_MANY_CLIENTS = gql`
  mutation CREATE_MANY_CLIENTS($data: CreateManyClientsInput!) {
    createManyClients(data: $data) {
      success
      err
    }
  }
`;

export const CREATE_BRAND_GROUP = gql`
  mutation CREATE_BRAND_GROUP($record: CreateOneBrandGroupInput!) {
    createBrandGroup(record: $record) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_BRAND_GROUP = gql`
  mutation UPDATE_BRAND_GROUP(
    $filter: FilterUpdateOneBrandGroupInput!
    $record: UpdateOneBrandGroupInput!
  ) {
    updateBrandGroup(filter: $filter, record: $record) {
      record {
        _id
      }
    }
  }
`;

export const CREATE_TRANSACTION = gql`
  mutation GIVE_CUSTOM_POINTS($data: GiveCustomPointsInput!) {
    giveCustomPoints(data: $data) {
      _id
    }
  }
`;

export const UPLOAD_MANY_CLIENTS = gql`
  mutation UPLOAD_MANY_CLIENTS($data: CreateManyClientInput!) {
    uploadClients(data: $data) {
      success
      err
    }
  }
`;

export const UPLOAD_MANY_VOLUME = gql`
  mutation UPLOAD_MANY_VOLUME($data: CreateManyVolumeInput!) {
    uploadVolume(data: $data) {
      success
      err
    }
  }
`;

export const UPLOAD_MANY_ACCOUNTS = gql`
  mutation UPLOAD_MANY_ACCOUNTS($data: CreateManyAccountInput!) {
    uploadAccountReceivable(data: $data) {
      success
      err
    }
  }
`;

export const UPLOAD_MANY_VOLUME_STICK = gql`
  mutation UPLOAD_MANY_VOLUME_STICK($data: CreateManyVolumeStickInput!) {
    uploadStickVolume(data: $data) {
      success
      err
    }
  }
`;

export const UPDATE_MANY_NOTIFICATIONS = gql`
  mutation UPDATE_MANY_NOTIFICATIONS(
    $filter: FilterUpdateManyNotificationInput!
    $record: UpdateManyNotificationInput!
  ) {
    updateNotifications(filter: $filter, record: $record) {
      numAffected
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation RESET_PASSWORD($data: ResetPasswordInput!) {
    resetPassword(data: $data) {
      success
      err
    }
  }
`;

export const DELETE_AREA = gql`
  mutation DELETE_AREA($filter: DeleteAreaInput!) {
    deleteArea(filter: $filter) {
      _id
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation DELETE_CATEGORY($filter: DeleteCategoryInput!) {
    deleteCategory(filter: $filter) {
      _id
    }
  }
`;

export const DELETE_SALE_CHANNEL = gql`
  mutation DELETE_SALE_CHANNEL($filter: DeleteSaleChannelInput!) {
    deleteSaleChannel(filter: $filter) {
      _id
    }
  }
`;

export const DELETE_PRICE_LIST = gql`
  mutation DELETE_PRICE_LIST($filter: DeletePriceListInput!) {
    deletePriceList(filter: $filter) {
      _id
    }
  }
`;

export const DELETE_REGION = gql`
  mutation DELETE_REGION($filter: DeleteRegionInput!) {
    deleteRegion(filter: $filter) {
      _id
    }
  }
`;

export const DELETE_ROUTE = gql`
  mutation DELETE_ROUTE($filter: DeleteRouteInput!) {
    deleteRoute(filter: $filter) {
      _id
    }
  }
`;

export const DELETE_ZONE = gql`
  mutation DELETE_ZONE($filter: DeleteZoneInput!) {
    deleteZone(filter: $filter) {
      _id
    }
  }
`;

export const DELETE_USER = gql`
  mutation DELETE_USER($filter: DeleteUserInput!) {
    deleteUser(filter: $filter) {
      _id
    }
  }
`;

export const DELETE_STORE_TYPE = gql`
  mutation DELETE_STORE_TYPE($filter: DeleteStoreTypeInput!) {
    deleteStoreType(filter: $filter) {
      _id
    }
  }
`;

export const CREATE_VOLUME_STICK = gql`
  mutation CREATE_VOLUME_STICK($record: CreateOneVolumeStickInput!) {
    createVolumeStick(record: $record) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_VOLUME_STICK = gql`
  mutation UPDATE_VOLUME_STICK(
    $filter: FilterUpdateOneVolumeStickInput!
    $record: UpdateOneVolumeStickInput!
  ) {
    updateVolumeStick(filter: $filter, record: $record) {
      record {
        _id
      }
    }
  }
`;

export const SEND_MASSIVE_EMAIL = gql`
  mutation SEND_MASSIVE_EMAIL($data: SendMassiveEmailInput!) {
    sendMassiveEmail(data: $data) {
      success
      err
    }
  }
`;

export const UPLOAD_DELETE_MANY_CLIENTS = gql`
  mutation UPLOAD_DELETE_MANY_CLIENTS($data: UploadDeleteManyClientsInput!) {
    uploadDeleteManyClients(data: $data) {
      success
      err
    }
  }
`;
